import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";

import { parseSlug, toKabobCase } from "../utils/utils";
import CartLink from "./cartLink";
import { ClientOnly } from "react-client-only";

const shopLinkPriority = {
  "Hand Towels": 1,
  "Tote Bag": 2,
};

function compare(order = "asc") {
  return function (a, b) {
    const first =
      a in shopLinkPriority ? shopLinkPriority[a] : Number.MAX_SAFE_INTEGER;
    const second =
      b in shopLinkPriority ? shopLinkPriority[b] : Number.MAX_SAFE_INTEGER;

    let result = 0;
    if (first < second) result = -1;
    else if (first > second) result = 1;
    return order === "desc" ? ~result : result;
  };
}

export const Nav = ({ className, status }) => {
  const { contentfulMenu, allShopifyProduct } = useStaticQuery(graphql`
    query {
      contentfulMenu(name: { eq: "Main Navigation" }) {
        items {
          label
          url
          entry {
            slug
          }
          subItems {
            label
            url
            entry {
              slug
            }
          }
        }
      }
      allShopifyProduct {
        productTypes: distinct(field: productType)
      }
    }
  `);

  let shopLinks = {
    label: "Categories",
    url: "/categories/",
    subLeft: true,
    subItems: allShopifyProduct.productTypes
      .filter((x) => x)
      .sort(compare())
      .map((productType) => ({
        label: productType,
        url: `/products/${toKabobCase(productType)}/`,
        entry: null,
      })),
  };

  return (
    <ClientOnly>
      <nav
        className={classNames(
          "absolute lg:static top-full lg:flex flex-grow justify-between w-full lg:w-auto lg:top-auto bg-studio-charcoal lg:bg-transparent",
          { open: status },
          className
        )}
      >
        <ul className="flex flex-col lg:flex-row m-0">
          <NavLinks links={contentfulMenu.items} />
        </ul>
        <ul className="flex flex-col lg:flex-row m-0">
          <NavLinks links={[shopLinks]} />
          <li className="nav-item hidden lg:block">
            <CartLink className="!flex items-center nav-link" />
          </li>
        </ul>
      </nav>
    </ClientOnly>
  );
};

const NavLinks = ({ className, links }) => {
  links = addLinkStatus(links);
  return (
    <>
      {links.map((link, i) => {
        return (
          <li className="nav-item" key={i}>
            {link.url === null && link.entry === null ? (
              <span
                className={classNames("nav-link no-to cursor-default", {
                  active: link.isActive,
                  direct: link.isDirect,
                })}
              >
                {link.label}
              </span>
            ) : (
              <Link
                to={link.entry ? parseSlug(link.entry.slug) : link.url}
                className={classNames("nav-link", {
                  active: link.isActive,
                  direct: link.isDirect,
                })}
              >
                {link.label}
              </Link>
            )}
            {link.subItems && link.subItems.length > 0 && (
              <SubNavMenu
                items={link.subItems}
                className={classNames({ left: link.subLeft || i >= 4 })}
              />
            )}
          </li>
        );
      })}
    </>
  );
};

const SubNavMenu = ({ items, className }) => {
  return (
    <ul className={classNames("subNav", className)}>
      {items.map((menuItem, index) => {
        return (
          <li className="subNav-item" key={index}>
            {menuItem.url === null && menuItem.entry === null ? (
              <span
                className={classNames("subNav-link no-to cursor-default", {
                  active: menuItem.isActive,
                  direct: menuItem.isDirect,
                })}
              >
                {menuItem.label}
              </span>
            ) : (
              <Link
                to={
                  menuItem.entry ? parseSlug(menuItem.entry.slug) : menuItem.url
                }
                className={classNames("subNav-link", {
                  active: menuItem.isActive,
                  direct: menuItem.isDirect,
                })}
              >
                {menuItem.label}
              </Link>
            )}
            {menuItem.subNav && <SubNavMenu items={menuItem.subNav} />}
          </li>
        );
      })}
    </ul>
  );
};

export const NavToggle = ({ status, onClick }) => {
  return (
    <button
      id="navToggle"
      className="lg:hidden"
      onClick={onClick}
      aria-label="Toggle Navigation"
    >
      <FontAwesomeIcon icon={status ? faTimes : faBars} size="2x" />
    </button>
  );
};

function addLinkStatus(menuItems) {
  var route = typeof window !== "undefined" ? window.location.pathname : "";
  menuItems.map((menuItem) => {
    let url = menuItem.entry ? parseSlug(menuItem.entry.slug) : menuItem.url;
    if (route === "/" || route === "") {
      menuItem.isActive = false;
      menuItem.isDirect = false;
      menuItem.subItems?.map((subItem) => {
        subItem.isActive = false;
        subItem.isDirect = false;
        return subItem;
      });
      return menuItem;
    }

    if (menuItem.subItems) {
      var hasActiveSubItem = false;
      menuItem.subItems.map((subItem) => {
        let url = subItem.entry ? parseSlug(subItem.entry.slug) : subItem.url;
        if (route === url) {
          subItem.isActive = true;
          subItem.isDirect = true;
          hasActiveSubItem = true;
        } else {
          subItem.isActive = false;
          subItem.isDirect = false;
        }
        return subItem;
      });
      menuItem.isActive = hasActiveSubItem || route === url;
      menuItem.isDirect = route === url;
    } else {
      menuItem.isActive = menuItem.isDirect = route === url;
    }
    return menuItem;
  });
  return menuItems;
}
