import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { StoreContext } from "../utils/store-context";

const CartLink = ({ ...props }) => {
  const { checkout } = React.useContext(StoreContext);

  const items = checkout ? checkout.lineItems : [];

  const quantity = items.reduce((total, item) => {
    return total + item.quantity;
  }, 0);

  return (
    <Link to="/cart/" {...props}>
      <FontAwesomeIcon icon={faShoppingCart} size="lg" />
      <span className="ml-2">{quantity}</span>
    </Link>
  );
};

export default CartLink;
