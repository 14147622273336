import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import moment from "moment";

const Footer = () => {
  const { site, contentfulMenu } = useStaticQuery(graphql`
    query {
      contentfulMenu(name: { eq: "Footer Navigation" }) {
        items {
          label
          url
          entry {
            slug
          }
        }
      }
      site {
        siteMetadata {
          regName
        }
      }
    }
  `);

  return (
    <footer>
      <div className="container mb-4 flex flex-col items-center">
        <ul className="flex gap-4">
          {contentfulMenu.items.map(({ label, url, entry }, i) => (
            <li key={i}>
              {entry ? (
                <Link to={entry.slug}>{label}</Link>
              ) : (
                <a href={url}>{label}</a>
              )}
            </li>
          ))}
        </ul>
        <div>
          <Link to="/terms-of-service/">Terms</Link> &bull;{" "}
          <Link to="/privacy-policy/">Privacy</Link> &bull;{" "}
          <span>
            &copy;&nbsp;{moment().format("Y")}&nbsp;{site.siteMetadata.regName}
          </span>
        </div>
      </div>
      {process.env.NODE_ENV !== "production" && (
        <div className="text-center p-4 bg-red-500 sm:bg-blue-500 md:bg-green-500 lg:bg-yellow-500 xl:bg-purple-500">
          Current screen size:&nbsp;
          <span className="inline-block sm:hidden">xs</span>
          <span className="hidden sm:inline-block md:hidden">sm</span>
          <span className="hidden md:inline-block lg:hidden">md</span>
          <span className="hidden lg:inline-block xl:hidden">lg</span>
          <span className="hidden xl:inline-block">xl</span>
        </div>
      )}
    </footer>
  );
};

export default Footer;
