import React from "react";
import classNames from "classnames";

import Header from "./header";
import Footer from "./footer";

const Layout = ({ noContainer, children }) => {
  return (
    <div id="gatsby__app">
      <div className="z-50 sticky top-0">
        <Header />
      </div>
      <main
        className={classNames({ "pt-6": !noContainer, "pb-16": !noContainer })}
      >
        {noContainer ? children : <div className="container">{children}</div>}
      </main>
      <Footer />
      <div id="overlay"></div>
    </div>
  );
};

export default Layout;
