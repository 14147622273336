import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { toKabobCase } from "../utils/utils";

const PageInfo = ({ title, slug, pageClass, lang, meta, description }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            subTitle
            description
          }
        }
      }
    `
  );

  if (!pageClass) {
    pageClass = !slug ? toKabobCase(title) : slug === "/" ? "index" : slug;
  }

  const metaDescription = description || site.siteMetadata.description;

  var baseTitle = `${site.siteMetadata.title} ${site.siteMetadata.subTitle}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: [`page-${pageClass}`],
      }}
      defaultTitle={baseTitle}
      title={title === "Home" ? "" : title}
      titleTemplate={`%s - ${baseTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link rel="preconnect" href="https://fonts.gstatic.com"></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Copse&family=New+Tegomin&display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
  );
};

PageInfo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

PageInfo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default PageInfo;
