import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery, Link } from "gatsby";

import { Nav, NavToggle } from "./nav";
import CartLink from "./cartLink";

const Header = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          subTitle
        }
      }
    }
  `);

  const [showNav, setShowNav] = useState(false);

  const handleNavToggle = (e) => {
    if (showNav) {
      setShowNav(false);
    } else if (!showNav) {
      setShowNav(true);
    }
  };

  return (
    <header className="p-8 py-5 md:py-8 bg-studio-charcoal">
      <Helmet bodyAttributes={{ "data-overlay": showNav }} />
      <div className="lg:container relative flex flex-row justify-between items-center">
        <div
          className="brand flex lg:flex items-center justify-between w-full lg:w-auto lg:mb-0"
          style={{ gridTemplateColumns: "1fr auto 1fr" }}
        >
          <NavToggle status={showNav} onClick={handleNavToggle} />
          <Link to="/" className="text-4xl sm:text-5xl font-new-tegomin mb-2">
            {site.siteMetadata.title}
          </Link>
          <CartLink className="font-bold lg:hidden" />
        </div>
        <Nav status={showNav} className="lg:ml-4" />
      </div>
    </header>
  );
};

export default Header;
