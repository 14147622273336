export const DATE_FORMAT = "YYYY-M-D";

export const getSelectedOption = (select) =>
  select.options[select.selectedIndex].value;

export const getSelectedRadioOption = (radioWrapper) =>
  radioWrapper.querySelector('input[type="radio"]:checked')?.value || "";

export const toKabobCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join("-");

export const toCapital = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const formatCurrency = (amount, currency) =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(amount);

export const formatPriceRange = (priceA, priceB, currency) => {
  let price = formatCurrency(priceA, currency);

  if (priceB && priceA !== priceB) {
    price = `${price} – ${formatCurrency(priceB, currency)}`;
  }

  return price;
};

export const isVisible = (element, container) => {
  let elementRect = element.getBoundingClientRect();
  let containerRect = container.getBoundingClientRect();

  return (
    elementRect.top >= containerRect.top &&
    elementRect.left >= containerRect.left &&
    elementRect.bottom <= containerRect.bottom &&
    elementRect.right <= containerRect.right
  );
};

export const getOrientation = (element) => {
  let { width, height } = element.getBoundingClientRect();
  return width > height ? "horizontal" : "vertical";
};

export const getOriginal = (image) => image.childImageSharp.original;

export const variantInCheckout = (checkout, variantId) =>
  checkout.lineItems.find((lineItem) => lineItem.variant.id === variantId);

export const variantQtyInCheckout = (checkout, variantId) => {
  let variant = checkout.lineItems.find(
    (lineItem) => lineItem.variant.id === variantId
  );

  if (variant) {
    return variant.quantity;
  }

  return 0;
};

export const getImageWithPlaceholder = (image, placeholder) => ({
  ...image,
  placeholder: {
    fallback: placeholder.images.fallback.src,
  },
});

export const parseSlug = (slug, prefix, relative = false) => {
  let pathParts = prefix
    ? (prefix + slug).split("/").filter((x) => x !== "")
    : slug.split("/").filter((x) => x !== "");

  return pathParts.length > 0
    ? `${!relative ? "/" : ""}${pathParts.join("/")}/`
    : "/";
};
